.nav-list .list {
  font-family: "Open Sans";
  color: "#1b1b1b";
}

.logo-sadeaf-header {
  margin: 20px 135px;
}

.link span:hover {
  color: #ee591f;
}

.container-header {
  /* padding-left: 120px; */
}

.publications:hover .container-submenu-news,
.container-submenu-news:hover {
  display: block;
}

.deaf-education:hover .container-submenu-services2,
.container-submenu-services2:hover {
  display: block;
}

.access-services:hover .container-submenu-sejrvices,
.container-submenu-services:hover {
  display: block;
}

.link:hover .container-submenu,
.container-submenu:hover {
  display: block;
}

.container-link {
  background-color: #f2951d !important;
  border-bottom: 1.5px solid #c64412;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.container-link:hover {
  background-color: #c64412 !important;
  transition: 0.3ms ease-out;
}

.link-submenu {
  color: #ffffff !important;
}

.icon {
  background-color: #d0d0d0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  height: 30px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-top: 0px;
  padding: 0px;
  text-align: center;
  transition: background 0.2s ease-in-out 0s;
  width: 30px;
  margin-left: 4px;
}

.facebook:hover {
  background-color: #4c66a4;
}
.youtube:hover {
  background-color: #cc2423;
}

.icon-img {
  color: #ffffff;
}

/* Large Screen */
@media screen and (min-width: 480px) {
  .container-submenu {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 200px;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
  }
  .container-submenu-services {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 200px;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 141px;
  }

  .container-submenu-services2 {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 200px;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 240px;
  }

  .container-submenu-news {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 200px;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 200px;
  }

  .container-icon {
    float: right;
    margin-right: 50px;
    margin-bottom: 5px;
  }
}

/* Mobile Screen */
@media screen and (max-width: 425px) {
  .logo-sadeaf-header {
    margin: 20px auto;
    display: block;
  }
  
  .container-submenu {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
  }
  .container-submenu-services {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 141px;
  }

  .container-submenu-services2 {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 240px;
  }

  .container-submenu-news {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;

    top: 240px;
  }

  .container-icon {
    display: flex;
    justify-content: center;
    margin-right: 50px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 376px) {
  .container-submenu {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
  }
  .container-submenu-services {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 141px;
  }

  .container-submenu-services2 {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 240px;
  }

  .container-submenu-news {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;

    top: 240px;
  }
}

@media screen and (max-width: 361px) {
  .container-submenu {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
  }
  .container-submenu-services {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 141px;
  }

  .container-submenu-services2 {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;
    left: 200px;
    top: 240px;
  }

  .container-submenu-news {
    display: none;
    border-radius: 3px;
    margin-top: 7px;
    width: 100%;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 50%);
    position: absolute;
    box-sizing: border-box;
    border-bottom: 1px solid #c64412;
    z-index: 9999;
    border: none;

    top: 240px;
  }
}
