@font-face {
  font-family: 'Helvetica Neue';
  src: url('../../../asset/HelveticaNeue-Roman.otf') format('opentype');
}

td {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 9vw;
}

.total-record-container {
  margin: 10px 20px;
}

.container-donation-list {
  padding: 20px;
}
.container-donation-list h1 {
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 30px;
}

.info-download {
  color: #337ab7;
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.icon-download {
  color: #337ab7;
}

.link-download:hover {
  color: rgb(48, 48, 177);
}

.upload-add-row-container {
  width: 100%;
  border: solid 1px #ccc;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.upload-add-form {
  margin: 0 5px;
  width: 20%;
  min-width: 9vw;
}

.react-bootstrap-table {
  overflow-x: auto;
  font-weight: 200 !important;
}

svg {
  height: 1rem !important;
  width: 1rem !important;
  margin-left: 1px;
  cursor: pointer;
}
