.link-navbar {
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
  color: #777;
  font-size: 14px;
  margin: 0 10px;
}

.container-navbar {
  padding: 20px 50px;
  background-color: #f8f8f8;
  width: 97%;
  margin: 0 auto;
  border-bottom: 1.2px solid #e7e7e7;
  display: flex;
}

.container-link-upload {
  display: flex;
  margin-left: 10px;
  background-color: unset !important;
  border-bottom: unset;
  min-height: unset;
  align-items: unset;
}

.container-link-upload:hover {
  background-color: unset !important;
}
.link-navbar {
  padding: 5px 50px;
}
