@font-face {
  font-family: "Helvetica Neue";
  src: url("../../asset/HelveticaNeue-Roman.otf") format("opentype");
}

.container-info-detail {
  padding: 0 19px 0 19px;
  margin-top: 5px;
}

.btn-cancel {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-left: 3px;
}

.btn-cancel-detail {
  background-color: #fff;
  border-color: #ccc;
  color: #333;
  width: 90px;
  height: 36px;
}

.btn-cancel-detail:hover {
  background-color: gray;
}

.container-content-detail {
  padding: 0 10vw;
}

.icon-cloud {
  margin: auto;
  height: 31px;
  width: 33px;
  margin: 0 auto;
}

.title-detail {
  font-size: 18px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #777777;
  background-color: #f5f5f5;
  padding: 10px;
}

.content-detail {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  margin: 0;
}

.content-detail {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #333333;
  margin: 0;
}
