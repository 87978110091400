.container-form-upload {
  width: 97%;
  margin: 0 auto;
  padding: 20px;
  margin-top: 30px;
}

.container-form-upload h2 {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 36px;
}

.container-form-upload p {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 5px;
}

.info-upload {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 11px;
}

.submit-upload {
  font-size: 14px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: #337ab7;
  border-color: #2e6da4;
  width: 70px;
  margin-top: 15px;
}

.submit-upload:hover {
  background-color: blue;
}
